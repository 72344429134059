import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

const NotFoundPage = ({location}) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <h1>Page not found</h1>
    <p>This page doesn't exist :(</p>
    <p>If you believe this to be in error and would like to let us know, you can go <a href="/contact">HERE</a> to let us know.</p>
  </Layout>
)

export default NotFoundPage
